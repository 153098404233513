import { Button, Col, Image, Card, Table, Tabs, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ShowAttrData, dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { AppStateContext } from "../../context/AppContext";
// import ReplyQuote from "./ReplyQuote";

const ViewRequested = ({}) => {
  const { request } = useRequest();
  const [quote, setQuote] = useState([]);
  const [replyQuote, setReplyQuote] = useState([]);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();

  const params = useParams();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.listQuote}/view/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setQuote(data.quote);
        setReplyQuote(data.reply);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ");
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getData(params.id);
  }, [params.id]);

  useEffect(() => {
    setPageHeading(lang("Quote  Management"));
  }, []);

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between"></div>
      </div>

      <div className="table-responsive customPagination withOutSearilNo">
        {loading ? (
          <Loader />
        ) : (
          <Card className="quote-card" title={lang("View Details")}>
          <div className="view-main-list">
              {quote?.vendor_id?.name ? <div className="label-quote-card view-inner-cls">
               <h5>{lang("Provider Details")} :</h5>
               <h6 className="view-quote-text">
                <h4> { quote?.vendor_id?.name }</h4> <h4> { quote?.vendor_id?.email }</h4>
               </h6>
             </div> : "" }
             {quote?.user_id?.name ? <div className="label-quote-card view-inner-cls">
               <h5>{lang("Customer Details")} :</h5>
               <h6 className="view-quote-text">
                <h4> { quote?.user_id?.name }</h4> <h4>{ quote?.user_id?.email }</h4>
               </h6>
             </div> : "" }
            {quote?.service_id?.service_id?.name ? <div className="label-quote-card view-inner-cls">
               <h5>{lang("Service Name")} :</h5>
               <h6 className="view-quote-text">
                 
                 {quote?.service_id?.service_id?.name}
               </h6>
             </div> : ""}
             {quote?.package_id?.name ? <div className="label-quote-card view-inner-cls">
               <h5>{lang("Package Name")} :</h5>
               <h6 className="view-quote-text">               
                 {quote?.package_id?.name}
               </h6>
             </div> : ""}
            <div className="label-quote-card view-inner-cls">
               <h5> {lang("Price")} :</h5>
               <h6 className="view-quote-text">
                 
                 {quote?.price ?? "0"}
               </h6>
             </div> 
            
             {quote?.description ? <div className="label-quote-card view-inner-cls">
               
               <h5>{lang("Description")} :</h5>
               <h6 className="view-quote-text">
                 { quote?.description}
               </h6>
             </div> : ""}
             {quote?.values?.length && quote?.values?.length > 0 ? (
               <div className="label-quote-card view-inner-cls">
                 <h5>{lang("Attributes")} :</h5>
              <div className="for-multipule-attribute">
              <ShowAttrData attributes={quote?.values}/>

              {/* {quote?.values?.map((item, index) => (
                 <h6 key={index} className="view-quote-text">
                  {item?.name && <p> {item?.name} :  {
                  item?.type === "package" ? (item?.value?.length && <p>{item?.value?.map((value)=>  `${value?.option} (${value?.tags?.join(", ")}) `)}</p>)
                    :
                  item?.type === "button" ? (item?.value && <p>{item?.info || 'Yes'}</p>)
                   : 
                   item?.type === "file" ? (item?.value ? <p><Image src={item?.value}/></p> : "")
                   : 
                   (item?.value && <p>{item?.value}</p>)
                    }
                    </p>}
                 
                 </h6>
               ))} */}

              </div>
               </div>
             ) : (
               ""
             )}
 
          </div>
         {quote?.image ? <div className="label-quote-card view-inner-cls">
               
               <h5>{lang("Image")} :</h5>
               <h6 className="view-quote-text">
                <Image src={quote?.image} />
               </h6>
             </div> : ""}
 
             <div className="quote-replay-img">
               {replyQuote ? (
                 <div className="quote-bottam-headding view-main-list">
                   <h4 className="provider-main8-your">Provider Reply Quote</h4>
                    <div className="label-quote-card view-inner-cls">
                     <h5>{lang("Price")} :{" "}</h5>
                     <h6 className="view-quote-text">
                       {" "}
                       {replyQuote?.price ? replyQuote?.price : "0"}
                     </h6>
                   </div> 
                  {replyQuote?.message ? <div className="label-quote-card view-inner-cls">
                    <h5> {lang("Message")} :{" "}</h5>
                     <h6 className="view-quote-text">
                       {" "}
                       {replyQuote?.message}
                     </h6>
                   </div> : "" }
                   {replyQuote?.images ? (
                     <div className="quote-img">
                       <Image src={replyQuote?.images?.[0]} />
                     </div>
                   ) : (
                     ""
                   )}
                 </div>
               ) : (
                 <b>{lang("Not Replied")}</b>
               )}
             </div>
           </Card>
        )}
      </div>
    </>
  );
};

export default ViewRequested;