import { Badge, Button, Col, Image, Input, Table, Tabs, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useNavigate } from "react-router";
import moment from "moment";
import { DownloadExcel } from "../../components/ExcelFile";
import { Severty, ShowToast } from "../../helper/toast";

const Requested = ({}) => {
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const navigate = useNavigate();

  const columns = [
    {
      title: lang("Service"),
      dataIndex: "service_id",
      key:"service_id",
      sorter: (a, b) => a?.service_id?.service_id?.name?.localeCompare(b?.service_id?.service_id?.name),
      sortDirections: ['ascend', 'descend'],
      render: (_, { service_id }) =>
        `${service_id?.service_id ? service_id?.service_id?.name : "-"}`,
    },
  

    {
      title: lang("Service Provider Name"),
      dataIndex: "provider",
      key: "provider",
      sorter: (a, b) => a?.vendor_id?.name?.localeCompare(b?.vendor_id?.name),
      sortDirections: ['ascend', 'descend'],
      render: (_, { vendor_id }) => `${vendor_id?.name || "-"}`,
    },
    {
      title: lang("Customer name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.user_id?.name?.localeCompare(b?.user_id?.name),
      sortDirections: ['ascend', 'descend'],
      render: (_, { user_id }) => `${user_id?.name}`,
    },
    // {
    //   title: lang("Quote status"),
    //   key: "action",
    //   render: (_, {status}) => {
    //     let statusColor = "yellow";
    //     let text = lang("request");
    //     if (status === "request") {
    //       statusColor = "cyan";
    //       text = lang("requested");
    //     } else if (status === "received") {
    //       statusColor = "cyan";
    //       text = lang("Received");
    //     } else if (status === "complete") {
    //       statusColor = "lime";
    //       text = lang("Complete");
    //     } else if (status === "fulfill") {
    //       statusColor = "lime";
    //       text = lang("Fulfill");
    //     }
    //     return (
    //       <Badge
    //         className="cap"
    //         status={statusColor ? statusColor : "default"}
    //         text={text}
    //       />
    //     );
    //   },
    // },
    {
      title: lang("Have Reply"),
      dataIndex: "have_reply",
      key: "have_reply",
      render: (_, { have_reply }) => `${have_reply === true ? "Yes" : "No"}`,
    },
    {
      title: lang("Requested On"),
      dataIndex: "created_at",
      key: "created_at",
      // sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
      // sortDirections: ['ascend', 'descend'],
      render: (_, { created_at }) => `${dateString(created_at, "ll")}`,
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View Detail")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button onClick={()=>navigate(`/quotation-request/view/${record?._id}`)} className="ms-sm-2 mt-xs-2 btnStyle btnOutlineDelete">
                {lang("View Detail")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const getData = (sorter) => {
    setLoading(true);
    request({
      url: apiPath.requestQuoteList + "?type=service",
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setList(data?.data || []);
        // setPagination((prev) => ({
        //   ...prev,
        //   current: pagination.current,
        //   total: data.totalDocs,
        // }));
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ");
      },
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let query = undefined;
    if (field && order) {
      query = `${field}=${order}`;
      console.log(query);
    }
    getData(pagination, filters, query);
  };
  useEffect(() => {
    getData(pagination);
  }, []);



  const getExportData = async () => {
    try {
   

      setExportLoading(true);
      request({
        url:
        apiPath.requestQuoteList +
          `?page=${pagination ? pagination.current : 1
          }&limit=${pagination ? pagination.total : 100000
          }&type=service`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data.data ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    console.log(exportData, "exportData")
    if (!exportData.length) return;

    const data = exportData.map((row) => ({
      "Provider Name": row?.vendor_id?.name ? row?.vendor_id?.name : "-",
      "Service Name": row?.service_id?.name ? row?.service_id?.name : "-",
      "Customer Name": row?.user_id?.name ? row?.user_id?.name : "-",
      "Have Reply" :  row?.have_reply ? "Yes" : "No",
      "Comments" :  row?.description ? row?.description : "-",
      "Image": row.image ? row.image  : "-",
      "Registered On": moment(row.created_at).format("DD-MM-YYYY"),
    }));
    DownloadExcel(data, "quote-request");
  };

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
          <h3>{lang("Requested List")}</h3>
          <Button
                  loading={exportLoading}
                  onClick={() => getExportData()}
                  className="primary_btn btnStyle"
                >
                  {lang("Export to Excel")}
                </Button>
        </div>
      
             
           
      </div>
      
               
           

      <div className="table-responsive customPagination withOutSearilNo">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            // current: pagination?.current,    
            defaultPageSize: 10,
            responsive: true,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          onChange={handleChange}
          className="ant-border-space"
        />
      </div>
    </>
  );
};

export default Requested;
